<template>
  <div class="manage-lawyer-edit">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-3">
          <h2 class="mb-5">Edit Lawyer</h2>

          <fd-form-section title="Account Credential">
            <fd-input
              v-model="lawyer.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Official Lawyer Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <div class="col-12 px-1">
              <button
                type="button"
                class="btn main bordered mb-3"
                @click="changePasswordModal.isShown = true"
              >
                Change Password
              </button>
            </div>
          </fd-form-section>
          <fd-form-section title="Personal Information">
            <fd-input
              v-model="lawyer.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <tel-input
              ref="tel"
              v-model="phoneInput"
              :tel.sync="lawyer.phoneNo"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
            <fd-input
              v-model="lawyer.lawyerFirmName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Lawyer Firm"
              name="lawyerFirm"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
          </fd-form-section>

          <div class="text-right p-2">
            <button class="btn main">Save</button>
          </div>
        </div>
      </fd-form>
    </div>
    <!-- Modal: Change Password -->
    <modal v-model="changePasswordModal.isShown">
      <fd-form class="change-password card p-3" @submit="changePassword">
        <h3 class="mb-3">Change Password</h3>

        <fd-input
          v-model="changePasswordModal.form.password"
          class="col-12 mb-2"
          label="New Password"
          name="newPassword"
          :type="showPassword ? 'text' : 'password'"
          :validators="[validator.required, validator.password]"
        >
          <template #append-icon>
            <span class="cursor-pointer" @click="showPassword = !showPassword">
              <i
                class="fa"
                :class="{
                  'fa-eye': !showPassword,
                  'fa-eye-slash': showPassword
                }"
              ></i>
            </span>
          </template>
        </fd-input>

        <div class="text-right pt-3">
          <button
            type="button"
            class="btn mr-1"
            @click="changePasswordModal.isShown = false"
          >
            Cancel
          </button>
          <button class="btn main">Update Password</button>
        </div>
      </fd-form>
    </modal>
  </div>
</template>

<script>
import TelInput from "@/modules/User/components/TelInput";

// Validator
import {
  required,
  password,
  email,
  phone
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// API
import { lawyer as lawyerAPI } from "@/api";
// Model
import { LawyerModel } from "@/models";
// Composable
import useUserSubmitHandler from "@/modules/User/composables/useUserSubmitHandler";

export default {
  setup(props, context) {
    let toLawyerList = () => {
      context.root.$router.push({
        name: "ManageLawyers"
      });
    };
    const submitHandlerOptions = {
      role: "lawyer",
      successCallback: toLawyerList
    };
    const { handleSubmitError } = useUserSubmitHandler(
      context,
      submitHandlerOptions
    );

    return {
      handleSubmitError
    };
  },
  name: "EditLawyer",
  components: { TelInput },
  mixins: [],
  props: {},
  data: function () {
    return {
      phoneInput: "",
      lawyer: {
        email: "",
        passsword: "",

        name: "",
        phoneNo: {},
        lawyerFirmName: ""
      },

      validator: {
        required: required,
        password: password,
        email: email,
        phone: phone
      },

      showPassword: false,

      changePasswordModal: {
        isShown: false,
        form: {
          passsword: ""
        }
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getLawyer();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    async getLawyer() {
      try {
        let data = await lawyerAPI.getLawyer(this.$route.params.id);
        this.lawyer = LawyerModel.getLawyerResponse(data);
        this.$refs.tel.setTel(this.lawyer);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get lawyer data. Please try again later."
        });
      }
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await lawyerAPI.updateLawyer(
          this.$route.params.id,
          LawyerModel.putLawyerPayload(this.lawyer)
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lawyer is updated successfully."
        });
        this.$router.push({ name: "ManageLawyers" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleSubmitError(error);
      }
    },
    async changePassword() {
      try {
        this.$store.commit("setIsLoading", true);
        await lawyerAPI.updatePassword(
          this.$route.params.id,
          this.changePasswordModal.form
        );
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lawyer's password has been updated successfully."
        });

        this.changePasswordModal.isShown = false;
        this.changePasswordModal.form.password = "";

        await this.getLawyer();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the password. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-lawyer-edit {
  form {
    @extend %formDesign;
  }
}
</style>
